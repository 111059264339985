<template>
  <v-skeleton-loader
    v-if="isSkeletonLoading"
    type="article, actions"
  ></v-skeleton-loader>

  <div v-else>
    <v-card>
      <v-card-title class="justify-center">
        Histórico da RI
      </v-card-title>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <v-timeline
              class="timeline-custom-dense timeline-custom-dots mt-4"
              dense
            >
              <v-timeline-item
                v-for="dataHistory in data"
                :key="dataHistory.id"
                class="mb-6"
                color="info"
                small
              >
                <div class="container">
                  <div class="container-image">
                    <v-avatar>
                      <v-img3
                        v-if="!dataHistory.url_photo"
                        src="@/assets/images/avatars/avatar-none.png"
                      ></v-img3>
                      <v-img
                        v-else
                        :src="dataHistory.url_photo"
                      ></v-img>
                    </v-avatar>
                  </div>
                  <div class="container-content">
                    <div>
                      <h3>{{ dataHistory.name }}</h3>
                    </div>

                    <div>
                      <span>{{ dateFormat(dataHistory.created_at) }}</span>
                    </div>
                    <br />
                  </div>
                </div>
                <div class="container-description">
                  {{ removeHtmlInString(dataHistory.text) }}
                </div>
              </v-timeline-item>
            </v-timeline>

            <div class="d-flex justify-end mt-5">
              <v-btn
                color="error"
                class="ml-5"
                @click="closeModal()"
              >
                Fechar
              </v-btn>
            </div>
          </v-card-text>
        </v-tab-item>

        <v-tab-item>
          <v-card-text>
            <v-timeline
              class="timeline-custom-dense timeline-custom-dots mt-4"
              dense
            >
              <v-timeline-item
                v-for="dataHistory in transferHistory"
                :key="dataHistory.id"
                class="mb-6"
                color="success"
                small
              >
                <div class="container">
                  <div class="container-content">
                    <div class="d-flex align-start mb-3 flex-sm-row flex-column">
                      <div>
                        <!-- Header -->
                        <div class="d-flex justify-space-between">
                          <p class="mb-1 font-weight-semibold text-base text--primary">
                            {{ dataHistory.user.created_name }}
                          </p>
                        </div>
                        <span>Alterou o emissor e destinatário.</span>
                      </div>
                    </div>
                    <div class="mb-10">
                      <p class="mb-1 text-base font-weight-semibold text--primary">
                        Data da alteração
                      </p>
                      <span>{{ dateFormat(dataHistory.created_at) }}</span>
                    </div>

                    <div
                      v-show="dataHistory.request_old_user"
                      class="mb-0"
                    >
                      <p class="mb-1 text-base font-weight-semibold text--primary">
                        Emissor anterior
                      </p>
                      <span>{{ dataHistory.request_old_user ? dataHistory.request_old_user.request_old_name : '' }}</span>
                    </div>

                    <div
                      v-show="dataHistory.request_old_user"
                      class="middle-icon"
                    >
                      <v-icon
                        size="30"
                        class=""
                      >
                        {{ icons.mdiSwapHorizontal }}
                      </v-icon>
                    </div>
                    <div
                      v-show="dataHistory.request_new_user"
                      class="mb-10"
                    >
                      <p class="mb-1 text-base font-weight-semibold text--primary">
                        Emissor atual
                      </p>
                      <span>{{ dataHistory.request_new_user ? dataHistory.request_new_user.request_new_name : '' }}</span>
                    </div>

                    <div
                      v-show="dataHistory.receiver_old_user"
                      class="mb"
                    >
                      <p class="mb-1 text-base font-weight-semibold text--primary">
                        Destinatário anterior
                      </p>
                      <span>{{ dataHistory.receiver_old_user ? dataHistory.receiver_old_user.receiver_old_name : '' }}</span>
                    </div>

                    <div
                      v-show="dataHistory.receiver_old_user"
                      class="middle-icon"
                    >
                      <v-icon
                        size="30"
                        class=""
                      >
                        {{ icons.mdiSwapHorizontal }}
                      </v-icon>
                    </div>

                    <div
                      v-show="dataHistory.receiver_new_user"
                      class=""
                    >
                      <p class="mb-1 text-base font-weight-semibold text--primary">
                        Destinatário atual
                      </p>
                      <span>{{ dataHistory.receiver_new_user ? dataHistory.receiver_new_user.receiver_new_name : '' }}</span>
                    </div>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>

            <div class="d-flex justify-end mt-5">
              <v-btn
                color="error"
                class="ml-5"
                @click="closeModal()"
              >
                Fechar
              </v-btn>
            </div>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClipboardTextOutline,
  mdiSwapHorizontal,
} from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    itemId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        mdiSwapHorizontal,
      },
      tab: '',
      tabs: [
        { title: 'HISTÓRICO', icon: mdiClipboardTextOutline },
        { title: 'TRANSFERÊNCIAS', icon: mdiSwapHorizontal },
      ],
      data: [],
      dataFromHistory: [],
      firstDataFromHistory: {},
      secondDataFromHistory: {},

      isSkeletonLoading: false,
      profilePhoto: '',
      transferHistory: [],
    }
  },

  created() {
    this.getDataFromHistory()
  },

  methods: {
    async getDataFromHistory() {
      this.isSkeletonLoading = true

      await axiosIns.get(`api/v1/ri/timeline_ri/${this.itemId}`).then(response => {
        const { data: responseData } = response.data

        // eslint-disable-next-line prefer-destructuring
        this.transferHistory = responseData.transferHistory.transfer_history

        if (this.dataIsEmpty(responseData)) {
          return
        }

        const firstData = responseData.answer[0]?.ri

        this.data.push({
          text: firstData.text,
          name: firstData.user_requester.name,
          profilePhoto: firstData.user_requester.url_photo,
          created_at: firstData.created_at,
        })

        responseData.answer.forEach(({
          id, text, user, created_at: createdAt, ri,
        }) => {
          this.data.push({
            id,
            text,
            name: user.name,
            created_at: createdAt,
            profilePhoto: ri?.user_requester.url_photo,
          })
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isSkeletonLoading = false
      })
    },

    dataIsEmpty(data) {
      if (data.answer.length) {
        return false
      }

      this.showMessage({
        title: 'Vazio!',
        text: 'O responsável pela solicitação ainda não respondeu',
      })

      // this.closeModal()

      return true
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
}
.container-image {
  margin-right: 20px;
}
.container-description {
  max-width: 30.5556rem;
  margin-right: 1.1111rem;
}

.tabs-just{
  display: flex;
  width: 100%;
}

.middle-icon{
  margin-left: -65px;
  padding: 6px;
  z-index: 100;
  background: #312D4B !important;
}
</style>
