<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card>
    <v-card-title class="justify-center">
      <span>Responder RI Nº.{{ data.protocol_number }}</span>
      <v-spacer></v-spacer>

      <v-tooltip
        v-if="editableProfile"
        left
        color="info"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="isExpand = !isExpand"
          >
            <v-icon
              color="info"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
        </template>

        <span>Editar</span>
      </v-tooltip>

      <v-tooltip
        right
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="openHistory()"
          >
            <v-icon
              color="warning"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiClipboardText }}
            </v-icon>
          </v-btn>
        </template>

        <span>Histórico</span>
      </v-tooltip>
    </v-card-title>

    <v-card-text
      :key="data.id"
      class="mt-2"
    >
      <div
        class="separator mb-8"
      >
        <div
          class="label"
        >
          <span style="font-size: 15px">
            Informações do RI
          </span>
        </div>
      </div>
      <v-row class="line">
        <v-text-field
          v-model="computedUserRequesterName"
          label="Emissor"
          readonly
          outlined
          dense
        />
        <v-text-field
          v-model="computedUserResponsibleName"
          label="Destinatário"
          readonly
          outlined
          dense
        />
      </v-row>

      <div
        v-show="isExpand"
        class="mb-0"
      >
        <p>
          Edite as informações a baixo ⚠
        </p>

        <div
          v-show="isExpand"
          class="separator mb-8"
        >
        </div>
      </div>

      <v-expand-transition>
        <v-card v-show="isExpand">
          <v-form ref="form">
            <v-row>
              <v-col span="12">
                <v-autocomplete
                  v-model="requestName"
                  :items="listEmployees"
                  item-value="id"
                  item-text="name"
                  label="Emissor"
                  outlined
                  dense
                />
              </v-col>

              <v-col span="12">
                <v-autocomplete
                  v-model="responsibleName"
                  :items="leaderList"
                  item-value="id"
                  item-text="name"
                  label="Destinatário"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-expand-transition>

      <v-row class="line">
        <v-text-field
          v-model="computedSolutionDate"
          label="Prazo para resolução"
          readonly
          outlined
          dense
        />

        <v-text-field
          v-model="computedUserLeaderName"
          label="Gerente regional"
          readonly
          outlined
          dense
        />
      </v-row>

      <v-row class="line">
        <v-text-field
          v-model="computedCreatedAt"
          label="Emissão"
          readonly
          outlined
          dense
        />

        <v-text-field
          v-model="computedFirstAnswerDate"
          label="Devolvido"
          readonly
          outlined
          dense
        />
      </v-row>

      <v-file-input
        v-show="computedStatusIsOpen"
        v-model="fileInput"
        :append-icon="icons.mdiPaperclip"
        label="Anexar novos arquivos"
        prepend-icon=""
        outlined
        multiple
        chips
        dense
      />
      <div
        class="container-description"
        v-html="computedDescription"
      />

      <div class="d-flex justify-center mb-4">
        <v-progress-circular
          v-show="isLoadingFile"
          color="warning"
          indeterminate
        />

        <div
          v-show="computedViewFileContainer"
          class="chips-container"
        >
          <v-chip
            v-for="file in fileList"
            :key="file.id"
            size="x-large"
            color="warning"
            class="chips-content"
            outlined
            dense
            @click="downloadFile(file.id, file.name)"
          >
            <span v-if="file.id === fileId ? false : true">{{ file.name }}</span>

            <v-progress-circular
              v-else
              :key="file.id"
              :size="25"
              color="white"
              indeterminate
            />
          </v-chip>
        </div>
      </div>

      <vue-editor
        v-show="computedStatusIsOpen"
        v-model="userResponse"
      />

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          class="mr-5"
          :disabled="isLoading"
          @click="closeModal(), clearData()"
        >
          Fechar
        </v-btn>

        <v-btn
          v-show="computedUserIsRequester"
          color="warning"
          class="mr-5"
          :disabled="isLoading"
          @click="finalizeComplaint()"
        >
          <span v-if="!isLoadingFinalizeComplaint">Finalizar RI</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>

        <v-btn
          v-if="isExpand"
          color="purple"
          :disabled="isLoading"
          @click="updateInfos"
        >
          <span v-if="!isLoadingUpdate">Atualizar</span>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>

        <v-btn
          v-else
          v-show="computedStatusIsOpen"
          color="info"
          :disabled="isLoading"
          @click="sendResponseData()"
        >
          <span v-if="!isLoadingResponse">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>

      <v-dialog
        v-model="showHistory"
        width="700px"
      >
        <History
          :key="data.id"
          :item-id="data.id"
          @close="showHistory = false"
        ></History>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar, mdiClipboardText, mdiPaperclip, mdiPencil,
} from '@mdi/js'
import DOMPurify from 'dompurify'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'
import History from './History.vue'

export default {
  components: {
    VueEditor,
    History,
  },

  mixins: [formatters, messages],

  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
  },

  data() {
    return {
      leaderList: [{}],
      requestName: '',
      responsibleName: '',
      isExpand: false,
      fileId: '',
      userResponse: '',

      showHistory: false,
      expansionPanel: true,
      isUserResponsible: false,
      viewFileContainer: false,

      isLoading: false,
      isLoadingResponse: false,
      isLoadingFile: false,
      isLoadingFinalizeComplaint: false,

      fileList: [],
      fileInput: [],

      icons: {
        mdiPencil,
        mdiCalendar,
        mdiPaperclip,
        mdiClipboardText,
      },
      isLoadingUpdate: false,
      listEmployees: [{}],
      editableProfile: false,
    }
  },

  computed: {
    computedViewFileContainer() {
      if (!this.isLoadingFile && this.viewFileContainer) {
        return true
      }

      return false
    },
    computedFirstAnswerDate() {
      return this.dateFormat(this.data?.first_answer) || 'Não respondido'
    },

    computedSolutionDate() {
      return this.dateFormat(this.data?.solution_date)
    },

    computedCreatedAt() {
      return this.dateFormat(this.data?.created_at)
    },

    computedDescription() {
      return DOMPurify.sanitize(this.data?.text)
    },

    computedUserResponsibleName() {
      return this.data?.user_responsible?.name
    },

    computedUserRequesterName() {
      return this.data?.user_requester?.name
    },

    computedUserLeaderName() {
      return this.data?.user_leader?.name || 'NÃO ATRIBUÍDO'
    },

    computedUserIsRequester() {
      const status = this.data.status_name
      const requestingUser = this.data.user_requester.name
      const loggedInUser = this.userProfile.name
      const isRequester = requestingUser === loggedInUser

      if (isRequester && status !== 'FECHADO') {
        return true
      }

      return false
    },

    computedStatusIsOpen() {
      const statusName = this.data.status_name
      if (statusName !== 'FECHADO') {
        return true
      }

      return false
    },
  },

  created() {
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.listEmployees = localStorageSlim.get('listEmployees', { decrypt: true })
    this.isUserResponsible = this.userProfile.name === this.data.user_responsible.name
    this.editableProfile = (this.userProfile.profile.profile === 'Controladoria' && this.userProfile.supervisor) || this.userProfile.profile.profile === 'Auditoria' || this.userProfile.profile.profile === 'Diretor'

    this.verifyStatusIsOpen()
    this.getImageList()
  },

  methods: {
    async updateInfos() {
      this.isLoadingUpdate = true
      this.isLoading = true

      const { data } = this

      const body = {
        users_requester_id: this.requestName || data.user_requester.id,
        users_responsible_id: this.responsibleName || data.user_responsible.id,
      }

      await axiosIns.put(`/api/v1/ri/update/${data.id}`, body).then(() => {
        this.showMessage({ title: 'Sucesso', text: 'Registro atualizado com sucesso', icon: 'success' })
        this.updatedTable()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoadingUpdate = false
        this.isLoading = false
        this.isExpand = false
        this.responsibleName = ''
        this.requestName = ''

        this.closeModal()
        this.updatedTable()
      })
    },

    async sendResponseData() {
      const { data, userResponse } = this
      this.isLoading = true
      this.isLoadingResponse = true

      const body = {
        text: userResponse.toUpperCase(),
        ris_id: data.id,
      }

      await axiosIns
        .post('api/v1/answer/store', body)
        .then(async () => {
          if (this.fileInput.length > 0) {
            await this.sendFiles(data.id)
          }

          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
          this.getImageList()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        }).finally(() => {
          this.isLoadingResponse = false
          this.isLoading = false
          this.closeModal()
        })
    },

    /**
     * @param {string} id
     */
    async sendFiles(id) {
      const formData = new FormData()

      this.fileInput.push(id)

      this.fileInput.forEach(file => {
        formData.append('files[]', file)
        formData.append('id', id)
      })

      await axiosIns.post('api/v1/attachment/submit_files', formData).then(() => this.clearData())
    },

    async finalizeComplaint() {
      const { data, userResponse } = this
      this.isLoadingFinalizeComplaint = true
      this.isLoading = true

      const todayDate = new Date()
      const body = {
        status_name: 'FECHADO',
        closing_date: todayDate,
      }

      const bodyAnswer = {
        text: userResponse.toUpperCase(),
        ris_id: data.id,
      }

      await Promise.all([
        axiosIns.post('api/v1/answer/store', bodyAnswer),
        axiosIns.put(`api/v1/ri/update/${data.id}`, body),
      ])
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Finalizado com sucesso!',
          })

          this.updatedTable()
          this.clearData()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        }).finally(() => {
          this.isLoadingFinalizeComplaint = false
          this.isLoading = false
          this.closeModal()
        })
    },

    async getImageList() {
      this.isLoadingFile = true

      await axiosIns.get(`api/v1/ri/attachment_ri/${this.data.id}`).then(response => {
        this.fileList = response.data.data

        if (this.fileList.length > 0) {
          this.viewFileContainer = true
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isLoadingFile = false
      })
    },

    /**
     * @param {string} idFromFile
     * @param {string} fileName
     */
    async downloadFile(idFromFile, fileName) {
      this.fileId = idFromFile

      const body = {
        id: idFromFile,
      }

      await axiosIns
        .post('api/v1/attachment/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']

          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
          this.clearData()
        })
        .catch(error => {
          this.showErrorMessage(error)

          this.fileId = ''
        })
    },

    verifyStatusIsOpen() {
      const isRequester = this.userIsRequester
      const statusName = this.data.status_name

      if (isRequester && statusName === 'FECHADO') {
        this.userIsRequester = false
      }

      if (statusName !== 'FECHADO') {
        this.statusIsOpen = true
      }
    },

    openHistory() {
      this.showHistory = true
    },

    clearData() {
      this.fileId = ''
      this.fileInput = []
      this.userResponse = ''
    },
  },
}
</script>

<style scoped>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.chips-container {
  width: 100%;
  height: auto;
  display: flex;
  padding: 15px;
  align-items: flex-start;

  border-radius: 10px;
  border: 0.0111rem solid #4e4a67;
}

.chips-content {
  margin-right: 0.4444rem;
}

.container-description {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;

  font-weight: 400;
  color: #e4e4e4;

  border-radius: 10px;
  border: 0.0111rem solid #4e4a67;
  overflow-y: scroll;
}

.line{
  gap: 10px;
  padding-right: 12px;
  padding-left: 12px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: -10px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}
</style>
